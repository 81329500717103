import {
  ApiSourceOfTruthCustomizationValuesToIgnore,
  ApiSourceOfTruthCustomizationValuesToIgnoreSchema,
  EnvironmentId,
  TagId,
} from 'api/apiTypes';
import routes from 'api/routes';
import { error, isErrored, isLoading, loading } from 'api/utils';
import { useContext, useEffect } from 'react';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export function useSourceOfTruthIgnoredValues(
  envGuid: EnvironmentId | null,
  tagId: TagId,
  match: boolean | 'page' | (string & { __regex: true })
) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const customizationKey = envGuid + tagId;
  const ignoredValues =
    match === true
      ? sourceOfTruthContext.globalIgnoredValuesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredValuesCache[customizationKey];

  useEffect(() => {
    if (envGuid && tagId && !ignoredValues) sourceOfTruthContext.getIgnoredValues(envGuid, tagId, match);
  }, [sourceOfTruthContext, tagId, match, ignoredValues, envGuid]);

  if (isLoading(ignoredValues)) return loading();
  if (isErrored(ignoredValues)) return error(ignoredValues.messages);

  return ignoredValues;
}

export function useCreateSourceOfTruthIgnoredValue(
  envGuid: EnvironmentId | null,
  tagId: TagId,
  match: string | boolean
) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { post } = useAxios();

  const customizationKey = envGuid + tagId;
  const ignoredValues =
    match === true
      ? sourceOfTruthContext.globalIgnoredValuesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredValuesCache[customizationKey];

  if (isLoading(ignoredValues)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const create = async (ignoredValues: ApiSourceOfTruthCustomizationValuesToIgnore, match: string | boolean) => {
    if (!envGuid) return;
    const data = ApiSourceOfTruthCustomizationValuesToIgnoreSchema.parse(ignoredValues);
    await post(routes.sourceOfTruth.ignoredValues.create(envGuid, tagId, encodeURIComponent(match)), data, {
      withCredentials: true,
    });

    const setState =
      match === true
        ? sourceOfTruthContext.setGlobalIgnoredValuesCache
        : sourceOfTruthContext.setPageIgnoredValuesCache;
    setState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
    if (match === true) {
      sourceOfTruthContext.globalIgnoredValueIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageIgnoredValueIds.current?.delete(customizationKey);
    }
  };

  return create;
}

export function useUpdateSourceOfTruthIgnoredValue(
  envGuid: EnvironmentId | null,
  tagId: TagId,
  match: string | boolean
) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { patch } = useAxios();

  const customizationKey = envGuid + tagId;

  const ignoredValues =
    match === true
      ? sourceOfTruthContext.globalIgnoredValuesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredValuesCache[customizationKey];

  if (isLoading(ignoredValues)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const update = async (ignoredValues: ApiSourceOfTruthCustomizationValuesToIgnore, match: string | boolean) => {
    if (!envGuid) return;
    const data = ApiSourceOfTruthCustomizationValuesToIgnoreSchema.parse(ignoredValues);
    await patch(routes.sourceOfTruth.ignoredValues.update(envGuid, tagId, encodeURIComponent(match)), data, {
      withCredentials: true,
    });

    const setState =
      match === true
        ? sourceOfTruthContext.setGlobalIgnoredValuesCache
        : sourceOfTruthContext.setPageIgnoredValuesCache;
    setState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
    if (match === true) {
      sourceOfTruthContext.globalIgnoredValueIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageIgnoredValueIds.current?.delete(customizationKey);
    }
  };

  return update;
}

export function useDeleteSourceOfTruthIgnoredValue(
  envGuid: EnvironmentId | null,
  tagId: TagId,
  match: string | boolean
) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { delete: deleteReq } = useAxios();

  const customizationKey = envGuid + tagId;

  const ignoredValues =
    match === true
      ? sourceOfTruthContext.globalIgnoredValuesCache[customizationKey]
      : sourceOfTruthContext.pageIgnoredValuesCache[customizationKey];

  if (isLoading(ignoredValues)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const update = async (match: string | boolean, groupPath: string) => {
    if (!envGuid) return;
    await deleteReq(routes.sourceOfTruth.ignoredValues.delete(envGuid, tagId, encodeURIComponent(match), groupPath), {
      withCredentials: true,
    });

    if (match === true) {
      sourceOfTruthContext.globalIgnoredValueIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageIgnoredValueIds.current?.delete(customizationKey);
    }

    const setState =
      match === true
        ? sourceOfTruthContext.setGlobalIgnoredValuesCache
        : sourceOfTruthContext.setPageIgnoredValuesCache;
    setState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
  };

  return update;
}
