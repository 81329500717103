import { useNextTagIds } from 'api';
import { TagId } from 'api/apiTypes';
import { MiddlewareEvent } from 'api/events';
import { useMiddlewareHookInit, useMiddlewareHookUpdate } from 'api/events/hooks';
import moment, { Moment } from 'moment';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DATE_PARAM, TAG_ID_PARAM } from 'utils/params/config';

import { useDashboardDate } from './useDashboardParams';
import { useReportGuids } from './useEnvGuid';

/**
 * Returns the selected report id from the url param, or
 * user default reportid, or the first report id in the
 * users reports.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useNextTagId(manual?: boolean): TagId {
  const { envGuid } = useReportGuids();
  const dateRange = useDateAvailableRange().currentRange;
  const tags = useNextTagIds(envGuid, dateRange);
  const [params, setParams] = useSearchParams();

  const tagIdFromParams = tags.data?.find((x) => x.tagId === params.get(TAG_ID_PARAM))?.tagId;

  useEffect(() => {
    // make sure reports and users are loaded, then make the assumption that
    // the report id in the URL is not valid
    if (!tagIdFromParams && tags.data?.length && params.get(TAG_ID_PARAM) !== null) {
      setParams((prev) => (prev.delete(TAG_ID_PARAM), prev));
    }
  }, [params, setParams, tagIdFromParams, tags.data?.length]);

  const tagId = (tagIdFromParams || tags.data?.[0]?.tagId || undefined) as TagId;

  return tagId;
}

/**
 * Returns the date from the URL param and clamps it to the past week excluding today.
 */
export function useDatePastWeekExcludingToday(): Moment {
  const [params] = useSearchParams();

  const dateFromParams = params.get(DATE_PARAM);

  return useMemo(() => {
    if (dateFromParams) {
      const date = moment(dateFromParams, 'YYYY-MM-DD');
      if (date.isValid()) {
        const yesterday = moment().subtract(1, 'day');
        const lastWeek = moment().subtract(1, 'week');
        if (date.isBefore(lastWeek)) {
          return lastWeek;
        } else if (date.isAfter(yesterday)) {
          return yesterday;
        } else {
          return date;
        }
      } else {
        return moment().subtract(1, 'day');
      }
    } else {
      return moment().subtract(1, 'day');
    }
  }, [dateFromParams]);
}

/**
 * Returns the available date range for the source of truth.
 */
export function useDateAvailableRange() {
  // const [params] = useSearchParams();

  // const dateFromParams = params.get(DATE_PARAM);
  const dates = useDashboardDate(undefined, true);

  return dates;
}

type DateRange = {
  fromDate: Moment | null;
  toDate: Moment | null;
  raw: {
    fromDate: string | null;
    toDate: string | null;
  };
};

export function useDatePastWeekExcludingTodayRange(parentEvent?: MiddlewareEvent) {
  const initEvent = useMiddlewareHookInit('Middleware', 'useDatePastWeekExcludingTodayRange()', parentEvent?.id);
  const updateEvent = useMiddlewareHookUpdate('Middleware', 'useDatePastWeekExcludingTodayRange()', initEvent?.id);

  const [params] = useSearchParams();

  const fromDate = params.get('fromDate');
  const toDate = params.get('toDate');

  const startDate = useMemo(() => moment().subtract(1, 'week').startOf('day'), []);
  const endDate = useMemo(() => moment().subtract(1, 'day').startOf('day'), []);

  const response = useMemo<DateRange>(() => {
    const dateData: DateRange = {
      fromDate: null,
      toDate: null,
      raw: {
        fromDate: fromDate,
        toDate: toDate,
      },
    };

    const fromDateMoment = moment(fromDate, 'YYYY-MM-DD');
    const toDateMoment = moment(toDate, 'YYYY-MM-DD');

    if (fromDateMoment.isValid()) {
      if (fromDateMoment.isAfter(startDate) && fromDateMoment.isBefore(endDate)) {
        dateData.fromDate = startDate;
      }
    }

    if (toDateMoment.isValid()) {
      if (toDateMoment.isAfter(startDate) && toDateMoment.isBefore(endDate)) {
        dateData.toDate = endDate;
      }
    }

    return dateData;
  }, [endDate, fromDate, startDate, toDate]);

  updateEvent.status = 'completed';

  return response;
}
