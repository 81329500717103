import { EnvironmentId, LabelId, TagId } from 'api/apiTypes';
import BoardId from 'api/types/report/boardId';
import ReportId from 'api/types/report/id';
import { Moment } from 'moment';

import { DashboardRoutes } from './dashboard';
import { DataExportRoutes } from './dataExport';
import { KnowledgeBaseRoutes } from './knowledgeBase';
import { MissingTagRulesRoutes } from './missingTagRules';
import NotificationsRoutes from './notifications';

export const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:5003/';

function toNewDate(date: Moment): string {
  return date.format('YYYY-MM-DD');
}

export default {
  userLogin: () => '/auth/login',
  isLoggedIn: () => `/auth/me`,
  forgetPassword: () => `/auth/forget-password`,
  validateOTP: () => `/auth/validate-otp`,
  setNewPassword: () => `/auth/set-password`,
  signUpUser: () => `/auth/confirm-user`,
  userInfo: () => `/auth/profile`,
  getReport: (reportId: ReportId) => `/auth/report/${reportId}`,
  getPrivacyConfig: (envGuid: string, dbGuid: string) => `/privacy/${envGuid}/${dbGuid}`,
  getPrivacyConsent: (envGuid: string, dbGuid: string) => `/privacy/consent/${envGuid}/${dbGuid}`,
  /*savePrivacyConsent: (envGuid: string, dbGuid: string) =>
    `https://sentineltest.free.beeceptor.com/privacy/consent/${envGuid}/${dbGuid}`,*/
  savePrivacyConsent: (envGuid: string, dbGuid: string) => `/privacy/consent/${envGuid}/${dbGuid}`,
  getExistingRules: (environmentId: string, insightId: string) => `/feedbackloop/find/${environmentId}/${insightId}`,
  deleteRule: () => `/feedbackloop/delete`,
  saveRule: () => `/feedbackloop/save`,
  createDataExport: () => `/dataExport/create`,
  notifications: NotificationsRoutes,
  missingTagRules: MissingTagRulesRoutes,
  knowledgeBase: KnowledgeBaseRoutes,
  dataExport: DataExportRoutes,
  dashboard: DashboardRoutes,
  // Source of truth
  sourceOfTruth: {
    publish: {
      getProgress: (envGuid: EnvironmentId, pastDay?: boolean) =>
        `/source-of-truth/last-publish-progress/${envGuid}${pastDay ?? true ? '' : '?pastDay=false'}`,
      post: () => `/source-of-truth/publish`,
    },
    getPageUrls: (envGuid: EnvironmentId, tagId: TagId, fromDate: Moment, toDate: Moment, search: string) =>
      `/source-of-truth/list-page-urls/${envGuid}/${tagId}?fromDate=${toNewDate(fromDate)}&toDate=${toNewDate(
        toDate
      )}&search=${search}`,
    getTagIds: (envGuid: EnvironmentId, fromDate: Moment, toDate: Moment) =>
      `/source-of-truth/tagIds/${envGuid}?fromDate=${toNewDate(fromDate)}&toDate=${toNewDate(toDate)}`,
    getGlobalSourceOfTruth: (envGuid: EnvironmentId, tagId: TagId, fromDate: Moment, toDate: Moment) =>
      `/source-of-truth/global/${envGuid}/${tagId}?fromDate=${toNewDate(fromDate)}&toDate=${toNewDate(toDate)}`,
    getPageSourceOfTruth: (
      envGuid: EnvironmentId,
      tagId: TagId,
      page: number,
      limit: number,
      summaryFilter: 'ALL' | 'AUTO' | 'NONE' | 'CUSTOM' | undefined,
      fromDate: Moment,
      toDate: Moment
    ) =>
      `/source-of-truth/page/${envGuid}/${tagId}?page=${page}&limit=${limit}${
        summaryFilter ? `&summaryFilter=${summaryFilter}` : ''
      }&fromDate=${toNewDate(fromDate)}&toDate=${toNewDate(toDate)}`,
    getSummary: (envGuid: EnvironmentId, tagId: TagId, fromDate: Moment, toDate: Moment) =>
      `/source-of-truth/summary/${envGuid}/${tagId}?fromDate=${toNewDate(fromDate)}&toDate=${toNewDate(toDate)}`,
    getCustomMatchCount: (envGuid: EnvironmentId, tagId: TagId, groupPath: string) =>
      `/source-of-truth/custom-sot-match-count/${envGuid}/${tagId}/${groupPath}`,
    autocompleteGroupPaths: (
      envGuid: EnvironmentId,
      tagId: TagId,
      _groupPath: string,
      fromDate: Moment,
      toDate: Moment
    ) => `/source-of-truth/group-paths/${envGuid}/${tagId}?fromDate=${toNewDate(fromDate)}&toDate=${toNewDate(toDate)}`,
    qualifiers: {
      get: (envGuid: EnvironmentId, tagId: TagId) => `/sot-customization/qualifiers/${envGuid}/${tagId}`,
      create: (envGuid: EnvironmentId, tagId: TagId) => `/sot-customization/qualifiers/${envGuid}/${tagId}`,
      update: (envGuid: EnvironmentId, tagId: TagId, qualifierId: string) =>
        `/sot-customization/qualifiers/${envGuid}/${tagId}/${qualifierId}`,
      delete: (envGuid: EnvironmentId, tagId: TagId, qualifierId: string) =>
        `/sot-customization/qualifiers/${envGuid}/${tagId}/${qualifierId}`,
    },
    customizations: {
      get: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean) =>
        `/sot-customization/custom-sot-rules/${envGuid}/${tagId}?match=${match}`,
      create: (envGuid: EnvironmentId, tagId: TagId) => `/sot-customization/custom-sot-rules/${envGuid}/${tagId}`,
      update: (envGuid: EnvironmentId, tagId: TagId, id: string) =>
        `/sot-customization/custom-sot-rules/${envGuid}/${tagId}/${id}`,
      delete: (envGuid: EnvironmentId, tagId: TagId, ruleId: string) =>
        `/sot-customization/custom-sot-rules/${envGuid}/${tagId}/${ruleId}`,
    },
    ignoredVariables: {
      get: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean) =>
        `/sot-customization/variables-to-ignore/${envGuid}/${tagId}?match=${match}`,
      create: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean) =>
        `/sot-customization/variables-to-ignore/${envGuid}/${tagId}?match=${match}`,
      delete: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean, groupPath: string) =>
        `/sot-customization/variables-to-ignore/${envGuid}/${tagId}?match=${match}&groupPath=${groupPath}`,
    },
    ignoredValues: {
      get: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean) =>
        `/sot-customization/values-to-ignore/${envGuid}/${tagId}?match=${match}`,
      create: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean) =>
        `/sot-customization/values-to-ignore/${envGuid}/${tagId}?match=${match}`,
      update: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean) =>
        `/sot-customization/values-to-ignore/${envGuid}/${tagId}?match=${match}`,
      delete: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean, groupPath: string) =>
        `/sot-customization/values-to-ignore/${envGuid}/${tagId}?match=${match}&groupPath=${groupPath}`,
    },
    addedValues: {
      get: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean) =>
        `/sot-customization/values-to-add/${envGuid}/${tagId}?match=${match}`,
      create: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean) =>
        `/sot-customization/values-to-add/${envGuid}/${tagId}?match=${match}`,
      update: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean) =>
        `/sot-customization/values-to-add/${envGuid}/${tagId}?match=${match}`,
      delete: (envGuid: EnvironmentId, tagId: TagId, match: string | boolean, groupPath: string) =>
        `/sot-customization/values-to-add/${envGuid}/${tagId}?match=${match}&groupPath=${groupPath}`,
    },
  },
  labels: {
    get: (envGuid: EnvironmentId, isApiBased = false) =>
      `/user-config/${envGuid}/insight-labels?isApiBased=${isApiBased}`,
    create: (envGuid: EnvironmentId, isApiBased = false) =>
      `/user-config/${envGuid}/insight-label?isApiBased=${isApiBased}`,
    update: (envGuid: EnvironmentId, labelId: LabelId, isApiBased = false) =>
      `/user-config/${envGuid}/insight-label/${labelId}?isApiBased=${isApiBased}`,
  },
  comments: {
    count: {
      get: (envGuid: EnvironmentId, insightId: BoardId, isApiBased = false) =>
        `/user-config/${envGuid}/${insightId}/commentsCount?isApiBased=${isApiBased}`,
    },
    get: (envGuid: EnvironmentId, insightId: BoardId, isApiBased = false) =>
      `/user-config/${envGuid}/${insightId}/comments?isApiBased=${isApiBased}`,
    create: (envGuid: EnvironmentId, insightId: BoardId, isApiBased = false) =>
      `/user-config/${envGuid}/${insightId}/comment?isApiBased=${isApiBased}`,
  },
};
