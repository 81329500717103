import { z } from 'zod';

export const NotificationThresholdSchema = z
  .object({
    unit: z.enum(['count', 'percent']),
    value: z.number().gte(0).int(),
    of: z.enum(['pageview', 'visitor', 'session']).optional(),
  })
  .refine((data) => data.unit !== 'percent' || (data.unit === 'percent' && data.value <= 100), {
    message: "Value must be less than or equal to 100 when unit is 'percent'",
    path: ['value'],
  });

type NotificationThreshold = z.infer<typeof NotificationThresholdSchema>;

export default NotificationThreshold;
