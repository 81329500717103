import { EnvironmentId, TagId } from 'api/apiTypes';
import { ApiSourceOfTruthCustomizationCustomMatchCountSchema } from 'api/apiTypes/sourceOfTruth/customMatchCount';
import routes from 'api/routes';
import { error, isErrored, isLoading, loading } from 'api/utils';
import { Moment } from 'moment';
import { useContext, useEffect } from 'react';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export function useSourceOfTruthSummary(envGuid: EnvironmentId | null, tagId: TagId, fromDate: Moment, toDate: Moment) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const key = `${tagId}-${fromDate.format('YYYY-MM-DD')}-${toDate.format('YYYY-MM-DD')}`;

  const summary = sourceOfTruthContext.summaryCache[key];

  useEffect(() => {
    if (envGuid && !summary) sourceOfTruthContext.getSummary(envGuid, tagId, fromDate, toDate);
  }, [sourceOfTruthContext, tagId, summary, envGuid, fromDate, toDate]);

  if (isLoading(summary)) return loading();
  if (isErrored(summary)) return error(summary.messages);

  return summary;
}

export function usePruneSourceOfTruthSummary(envGuid: EnvironmentId | null, tagId: TagId) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const summary = sourceOfTruthContext.summaryCache[tagId];

  if (isLoading(summary)) return loading();

  const pruneSummary = async () => {
    if (!envGuid) return;
    sourceOfTruthContext.pruneSummary(envGuid, tagId);
  };

  return pruneSummary;
}

export function useCountSourceOfTruthCustomRules(envGuid: EnvironmentId | null, tagId: TagId) {
  const { get } = useAxios();

  const countCustomRules = async (groupPath: string) => {
    if (!envGuid) return;
    const payload = await get(routes.sourceOfTruth.getCustomMatchCount(envGuid, tagId, groupPath), {
      withCredentials: true,
    });
    const data = ApiSourceOfTruthCustomizationCustomMatchCountSchema.parse(payload.data);
    return data;
  };

  return countCustomRules;
}
