//import { SourceOfTruthContext } from '.';

import { EnvironmentId, TagId } from 'api/apiTypes';
import ApiSourceOfTruthQualifier, { ApiSourceOfTruthQualifierSchema } from 'api/apiTypes/sourceOfTruth/qualifier';
import routes from 'api/routes';
import { error, loading } from 'api/utils';
import useSWR, { useSWRConfig } from 'swr';

import { useAxios } from 'utils/transport/useAxios';

// import { SourceOfTruthContext } from './provider';

export function useSourceOfTruthQualifiers(envGuid: EnvironmentId | null, tagId: TagId) {
  // const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const { get } = useAxios();

  // const qualifiers = sourceOfTruthContext.qualifiersCache[tagId];

  const response = useSWR(
    envGuid && tagId ? routes.sourceOfTruth.qualifiers.get(envGuid, tagId) : null,
    async (url) => {
      const response = await get(url, {
        withCredentials: true,
      });

      const data = ApiSourceOfTruthQualifierSchema.array().parse(response.data);

      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      errorRetryCount: 0,
      errorRetryInterval: 0,
    }
  );

  if (response.error) return error();
  if (response.isLoading || response.isValidating) return loading();

  return response.data;

  // useEffect(() => {
  //   if (currentReport && tagId && !qualifiers) sourceOfTruthContext.getQualifiers(currentReport, tagId);
  // }, [currentReport, reportId, sourceOfTruthContext, tagId, qualifiers]);

  // if (isLoading(currentReport)) return loading();
  // if (isErrored(currentReport)) return error();

  // if (isLoading(qualifiers)) return loading();
  // if (isErrored(qualifiers)) return error(qualifiers.messages);

  // return qualifiers;
}

export function useCreateSourceOfTruthQualifier(envGuid: EnvironmentId | null, tagId: TagId) {
  // const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { post } = useAxios();
  const { mutate } = useSWRConfig();

  const url = envGuid ? routes.sourceOfTruth.qualifiers.get(envGuid, tagId) : null;

  // if (isLoading(qualifiers)) return loading();
  // if (isErrored(qualifiers)) return error(qualifiers.messages);

  const create = async (qualifier: ApiSourceOfTruthQualifier) => {
    if (!envGuid) return;

    const _data = ApiSourceOfTruthQualifierSchema.parse(qualifier);
    const data = _data;
    await post(routes.sourceOfTruth.qualifiers.create(envGuid, tagId), data, {
      withCredentials: true,
    });

    if (url) mutate(url);
    // sourceOfTruthContext.setQualifiersCache((prev) => ({
    //   ...prev,
    //   [tagId]: undefined, //withoutError(prev[tagId])?.concat(_data),
    // }));
    // sourceOfTruthContext.qualifierIds.current?.delete(tagId);
  };

  return create;
}

export function useUpdateSourceOfTruthQualifier(envGuid: EnvironmentId | null, tagId: TagId) {
  // const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { patch } = useAxios();
  const { mutate } = useSWRConfig();

  const url = envGuid ? routes.sourceOfTruth.qualifiers.get(envGuid, tagId) : null;

  // if (isLoading(qualifiers)) return loading();
  // if (isErrored(qualifiers)) return error(qualifiers.messages);

  const update = async (qualifier: ApiSourceOfTruthQualifier) => {
    if (!envGuid) return;
    const _data = ApiSourceOfTruthQualifierSchema.parse(qualifier);
    const data = _data;
    await patch(routes.sourceOfTruth.qualifiers.update(envGuid, tagId, data.id), data, {
      withCredentials: true,
    });

    if (url) mutate(url);

    // sourceOfTruthContext.setQualifiersCache((prev) => ({
    //   ...prev,
    //   [tagId]: undefined,
    // }));
    // sourceOfTruthContext.qualifierIds.current?.delete(tagId);
  };

  return update;
}

export function useDeleteSourceOfTruthQualifier(envGuid: EnvironmentId | null, tagId: TagId) {
  // const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { delete: deleteRequest } = useAxios();
  const { mutate } = useSWRConfig();

  const url = envGuid ? routes.sourceOfTruth.qualifiers.get(envGuid, tagId) : null;

  // const qualifiers = sourceOfTruthContext.qualifiersCache[tagId];

  // if (isLoading(qualifiers)) return loading();
  // if (isErrored(qualifiers)) return error(qualifiers.messages);

  const update = async (qualifierId: string) => {
    if (!envGuid) return;
    await deleteRequest(routes.sourceOfTruth.qualifiers.delete(envGuid, tagId, qualifierId), {
      withCredentials: true,
    });

    if (url) mutate(url);

    // sourceOfTruthContext.setQualifiersCache((prev) => ({
    //   ...prev,
    //   [tagId]: withoutError(prev[tagId])?.filter((q) => q.id !== qualifierId),
    // }));
  };

  return update;
}
