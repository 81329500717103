import { routes, useAxios } from 'api';
import { DateRange } from 'api/date';
import { MiddlewareError, MiddlewareEvent } from 'api/events';
import { useMiddlewareHookInit, useMiddlewareHookUpdate } from 'api/events/hooks';
import { ClickHouseApiQueryVariables } from 'api/pages/schemas/clickhouse';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { DATE_PARAM } from 'utils/params/config';

import { useReportGuids } from './useEnvGuid';

export function useDashboardParams(): ClickHouseApiQueryVariables | null {
  const { envGuid } = useReportGuids();

  const date = useDashboardDate();

  const queryVariables = useMemo<ClickHouseApiQueryVariables | null>(() => {
    if (!date.current) {
      return null;
    }
    const dateString = date.current.format('YYYY-MM-DD');
    return {
      environment_guid: envGuid ?? '',
      from_date: dateString,
      to_date: dateString,
    };
  }, [date, envGuid]);

  return queryVariables;
}

type DashboardDateMeta = {
  error: any;
  isLoading: boolean;
  current: Moment | null;
  currentRange: DateRange;
  maxRange: DateRange;
  availableDates: Moment[];
  availableDatesMap: Record<string, Moment>;
};

/**
 * Returns the date from the URL param and clamps it to the past week excluding today.
 *
 * warning: EXCLUSIVE TO API DASHBOARD
 */
export function useDashboardDate(parentEvent?: MiddlewareEvent, range = false): DashboardDateMeta {
  const initEvent = useMiddlewareHookInit('Dashboard', 'useDashboardDate()', parentEvent?.id);
  const updateEvent = useMiddlewareHookUpdate('Dashboard', 'useDashboardDate()', initEvent?.id);

  const [params] = useSearchParams();
  const { get } = useAxios();
  const { envGuid } = useReportGuids();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, error, isLoading } = useSWR(
    envGuid ? routes.dashboard.availableDates(envGuid) : null,
    async (url) => {
      const fetchEvent = new MiddlewareEvent({
        namespace: 'Dashboard',
        source: 'network',
        method: 'POST',
        name: 'SWR Axios Request',
        path: `useSWR()`,
        data: url,
        parent: updateEvent.id, // to record the init AND the update event
      });

      let data: unknown = null;

      try {
        const response = await get(url, {
          withCredentials: true,
        });

        data = response.data;
        fetchEvent.status = 'completed';

        if (!Array.isArray(data)) {
          throw new Error('Data is not an array');
        }
      } catch (e) {
        fetchEvent.status = 'failed';
        fetchEvent.resultText = `${e}`;

        throw new MiddlewareError({
          cause: e instanceof Error ? e : new Error(`${e}`),
          event: fetchEvent,
          viewType: 'page',
        });
      }

      const postProcessEvent = new MiddlewareEvent({
        namespace: 'Dashboard',
        name: 'Post Process Dates',
        path: 'useSWR()',
        data,
        parent: fetchEvent.id,
        source: 'validate',
      });

      try {
        const moments = data.map((x) => moment(x));

        const dateMap = moments.reduce((acc, x) => {
          acc[x.format('YYYY-MM-DD')] = x;
          return acc;
        }, {} as Record<string, Moment>);

        postProcessEvent.status = 'completed';

        return {
          availableDates: moments,
          dateMap,
        };
      } catch (e) {
        postProcessEvent.status = 'failed';
        postProcessEvent.resultText = `${e}`;

        throw new MiddlewareError({
          cause: e instanceof Error ? e : new Error(`${e}`),
          event: postProcessEvent,
          viewType: 'page',
        });
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      errorRetryCount: 2,
    }
  );

  const fromDate = range ? params.get('fromDate') : params.get(DATE_PARAM);
  const toDate = range ? params.get('toDate') : null;

  const response = useMemo<DashboardDateMeta>(() => {
    const dateData: DashboardDateMeta = {
      error: null,
      isLoading: false,
      current: null,
      currentRange: {
        fromDate: null,
        toDate: null,
      },
      maxRange: {
        fromDate: null,
        toDate: null,
      },
      availableDates: [],
      availableDatesMap: {},
    };

    if (!envGuid || isLoading) {
      dateData.isLoading = true;
      return dateData;
    }

    if (error) {
      dateData.error = error;
      return dateData;
    }

    if (data) {
      dateData.availableDates = data.availableDates;
      dateData.availableDatesMap = data.dateMap;

      const latestDate = moment.max(data.availableDates);

      dateData.current = latestDate;
      dateData.currentRange.fromDate = latestDate;
      dateData.currentRange.toDate = latestDate;
      dateData.maxRange = {
        fromDate: moment.min(data.availableDates),
        toDate: latestDate,
      };

      if (fromDate) {
        const date = moment(fromDate, 'YYYY-MM-DD');

        const validDate = date.isValid() && data.availableDates.some((x) => x.isSame(date, 'day'));

        if (validDate) {
          if (range) {
            dateData.currentRange.fromDate = date;
          } else {
            dateData.current = date;
          }
        }
      }

      if (range && toDate) {
        const date = moment(toDate, 'YYYY-MM-DD');

        const validDate = date.isValid() && data.availableDates.some((x) => x.isSame(date, 'day'));

        if (validDate) {
          dateData.currentRange.toDate = date;
        }
      }
    }

    return dateData;
  }, [data, envGuid, error, fromDate, isLoading, range, toDate]);

  updateEvent.status = 'completed';

  return response;
}

/**
 * Returns the date from the URL param and clamps it to the past week excluding today.
 *
 * NOTE: DOES NOT WORK WITH API DASHBOARD
 */
export function useDatePastWeekd(): {
  current: Moment;
  today: Moment;
  lastWeek: Moment;
} {
  const [params] = useSearchParams();

  const dateFromParams = params.get(DATE_PARAM);

  return useMemo(() => {
    const today = moment();
    const lastWeek = moment().subtract(1, 'week');
    if (dateFromParams) {
      const date = moment(dateFromParams, 'YYYY-MM-DD');
      if (date.isValid()) {
        if (date.isBefore(lastWeek)) {
          return { current: lastWeek, today, lastWeek };
        } else if (date.isAfter(today)) {
          return { current: today, today, lastWeek };
        } else {
          return {
            current: date,
            today,
            lastWeek,
          };
        }
      } else {
        return {
          current: today,
          today,
          lastWeek,
        };
      }
    } else {
      return { current: today, today, lastWeek };
    }
  }, [dateFromParams]);
}
