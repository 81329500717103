import { ApiSourceOfTruth, ApiSourceOfTruthRuleSchema, EnvironmentId, TagId } from 'api/apiTypes';
import { ApiSourceOfTruthPageFilter, ApiSourceOfTruthPageFilterSchema } from 'api/apiTypes/sourceOfTruth/pageFilters';
import { DateRange } from 'api/date';
import routes from 'api/routes';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import { error, isErrored, isLoading, loading } from 'api/utils';
import { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { z } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export * from './summary';
export * from './qualifiers';
export * from './ignoredValues';
export * from './customizations';
export * from './ignoredVariables';
export * from './missingTagRules';

export function useSourceOfTruth(
  envGuid: EnvironmentId | null,
  tagId: TagId,
  date?: DateRange,
  limit?: number
): ApiSourceOfTruth | ApiError | ApiLoading {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const sourceOfTruth = sourceOfTruthContext.sourceOfTruthCache[tagId];

  useEffect(() => {
    if (tagId && envGuid && !sourceOfTruth) sourceOfTruthContext.getSourceOfTruth(envGuid, tagId, false, date, limit);
  }, [date, envGuid, limit, sourceOfTruth, sourceOfTruthContext, tagId]);

  if (isLoading(sourceOfTruth)) return loading();
  if (isErrored(sourceOfTruth)) return error(sourceOfTruth.messages);

  return sourceOfTruth;
}

export function useGlobalSourceOfTruth(
  envGuid: EnvironmentId | null,
  tagId: TagId,
  date?: DateRange,
  limit?: number
): ApiSourceOfTruth | ApiError | ApiLoading {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const key = `${tagId}-${date?.fromDate?.format('YYYY-MM-DD')}-${date?.toDate?.format('YYYY-MM-DD')}`;
  const sourceOfTruth = sourceOfTruthContext.globalSourceOfTruthCache[key];

  useEffect(() => {
    if (tagId && envGuid && !sourceOfTruth) sourceOfTruthContext.getSourceOfTruth(envGuid, tagId, true, date, limit);
  }, [date, envGuid, limit, sourceOfTruth, sourceOfTruthContext, tagId]);

  if (isLoading(sourceOfTruth)) return loading();
  if (isErrored(sourceOfTruth)) return error(sourceOfTruth.messages);

  return sourceOfTruth;
}

export function usePaginateGlobalSourceOfTruth(envGuid: EnvironmentId | null, tagId: TagId) {
  const { get } = useAxios();

  if (!tagId || !envGuid) return error();

  const paginate = async (_page: number, _pageSize: number, date?: DateRange) => {
    if (!date?.fromDate || !date.toDate) throw new Error('Date range is required');
    const payload = await get(
      routes.sourceOfTruth.getGlobalSourceOfTruth(envGuid, tagId, date?.fromDate, date?.toDate),
      {
        withCredentials: true,
      }
    );

    const data = ApiSourceOfTruthRuleSchema.array().parse(payload.data);
    const newSotData = {
      ...data,
      rules: data.map((x) => ({
        ...x,
        groupPathFriendly: `${x.friendlyName} (${x.groupPath})`,
      })),
    };
    return newSotData;
  };

  return paginate;
}

export function useFilterPageSourceOfTruth(envGuid: EnvironmentId | null, tagId: TagId) {
  const { post } = useAxios();

  if (!tagId || !envGuid) return error();

  const filter = async (
    filters: ApiSourceOfTruthPageFilter[],
    page: number,
    pageSize: number,
    summaryFilter?: 'ALL' | 'NONE' | 'AUTO' | 'CUSTOM',
    date?: DateRange
  ) => {
    if (!date?.fromDate || !date.toDate) throw new Error('Date range is required');
    const data = ApiSourceOfTruthPageFilterSchema.array().parse(filters);
    const payload = await post(
      routes.sourceOfTruth.getPageSourceOfTruth(
        envGuid,
        tagId,
        page,
        pageSize,
        summaryFilter,
        date?.fromDate,
        date?.toDate
      ),
      {
        filters: data,
      },
      {
        withCredentials: true,
      }
    );
    // const filterResponseSchema = z
    //   .object({
    //     ...ApiSourceOfTruthSchema.shape,
    //     rules: z.array(
    //       z.object({
    //         ...ApiSourceOfTruthSchema.shape.rules.element.shape,
    //         pageUrl: z.string(),
    //         groupPath: z.union([z.array(z.string()), z.string()]),
    //         groupPathFriendly: z.object({
    //           friendlyName: z.string(),
    //           technicalName: z.string().optional(),
    //         }),
    //         groupPathTranslations: z.undefined(),
    //         pageViewsWithVariable: z.number().optional(),
    //         pageViewsWithTag: z.number().optional(),
    //         percentValuePopulated: z.number().optional(),
    //       })
    //     ),
    //     totalPages: z.union([z.number().optional(), z.null()]),
    //     totalRows: z.union([z.number().optional(), z.null()]),
    //   })
    //   .transform((data) => ({
    //     ...data,
    //     rules: data.rules.map((x) => ({
    //       ...x,
    //       groupPathTranslations: x.groupPathFriendly,
    //     })),
    //   }));
    const PageFilterSchema = z.object({
      rules: z.array(ApiSourceOfTruthRuleSchema),
      totalPages: z.number(),
      totalRows: z.number(),
    });
    const newSotData = PageFilterSchema.parse(payload.data);
    const sotData = newSotData.rules.map((rule) => ({
      ...rule,
      groupPathFriendly: `${rule.friendlyName} (${rule.groupPath})`,
      // groupPath: typeof rule.groupPath === 'string' ? rule.groupPath : rule.groupPath.join(';'),
    }));

    return {
      data: sotData,
      totalPages: newSotData.totalPages,
      totalRows: newSotData.totalRows,
    };
  };

  return filter;
}

const autocompleteResponseSchema = z.object({
  groupPath: z.string(),
  friendlyName: z.string(),
  technicalName: z.string(),
});

export function useAutocompleteSourceOfTruthGroupPath(
  envGuid: EnvironmentId | null,
  tagId: TagId | null,
  date: DateRange
) {
  const { get } = useAxios();

  return useSWR(
    envGuid && tagId && date?.fromDate && date.toDate
      ? routes.sourceOfTruth.autocompleteGroupPaths(envGuid, tagId, '', date.fromDate, date.toDate)
      : null,
    async (url) => {
      const response = await get(url, {
        withCredentials: true,
      });

      // eslint-disable-next-line sentinelinsights/no-mutations
      return autocompleteResponseSchema
        .array()
        .parse(response.data)
        .sort((a, b) => `${a.friendlyName} (${a.groupPath})`.localeCompare(`${b.friendlyName} (${b.groupPath})`));
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 1,
    }
  );
}
