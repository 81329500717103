import { ClassNames } from '@emotion/react';
import styled from '@emotion/styled';
import { ArrowDropDown, CalendarMonth } from '@mui/icons-material';
import { Button, TextField, Theme } from '@mui/material';
import { DatePicker as MuiDatePicker, LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import React, { useEffect, useRef, useState } from 'react';

//#region types
/**
 * The type for each renderable calendar day.
 * @typedef {Object} DayPickerProps
 * @property {boolean} dayIsBetween - Is the given calendar day between the start and end dates?
 * @property {boolean} isStart - Is the given calendar day the start date of the range?
 * @property {boolean} isEnd - Is the given calendar day the end date of the range?
 * @property {boolean} isWeekStart - Is the given calendar day the start of a week inside the range?
 * @property {boolean} isWeekEnd - Is the given calendar day the end of a week inside the range?
 * @property {boolean} isMonthStart - Is the given calendar day the start of a month inside the range?
 * @property {boolean} isMonthEnd - Is the given calendar day the end of a month inside the range?
 * @property {boolean} isOutsideRange - Is the given calendar day outside the range?
 * @property {Theme} theme - The theme object
 */
interface DayPickerProps extends PickersDayProps<Moment> {
  isEnd: boolean;
  isToday: boolean;
  isStart: boolean;
  isWeekEnd: boolean;
  isMonthEnd: boolean;
  isWeekStart: boolean;
  isMonthStart: boolean;
  dayIsBetween: boolean;
  isOutsideRange: boolean;
  theme?: Theme;
}

/**
 * The type for input props of the Date Picker.
 * @typedef {Object} MuiDatePickerProps
 * @property {Moment} start - The start date of the range
 * @property {Moment} end - The end date of the range
 * @property {Function} setStart - The function to set the start date of the range
 * @property {Function} setEnd - The function to set the end date of the range
 * @property {boolean} demo - Is the date picker being used in the UI demo page
 */
type MuiBaseDatePickerProps = Omit<React.ComponentProps<typeof MuiDatePicker>, 'onChange' | 'value' | 'renderInput'>;
interface DatePickerProps extends MuiBaseDatePickerProps {
  start: Moment | null;
  end: Moment | null;
  setStart: (date: Moment | null) => void;
  setEnd: (date: Moment | null) => void;
  buttonProps?: Partial<React.ComponentProps<typeof Button>>;
  variant?: 'button' | 'textinput';
  error?: string;
  required?: boolean;
}
//#endregion

//#region styles
/**
 * The styles for entire date picker component, in this case, we remove the
 * margins from the week name header in order to allow for each day to
 * seamlessly connect to the next.
 *
 * The className prop on the base MuiDatePicker component is not forwarded
 * to the Popper component for obvious reasons. So instead, we use
 * dynamic styles to apply our css directly to the Popper component.
 * @param {MuiDatePicker} props - The props for the date picker component
 * @returns ReactJSXElement
 */
const StyledDatePicker = (props: React.ComponentProps<typeof MuiDatePicker>) => (
  <ClassNames>
    {({ css }) => (
      <MuiDatePicker
        PopperProps={{
          className: css`
            .MuiDayPicker-weekDayLabel {
              margin: 0;
            }
          `,
        }}
        {...props}
      />
    )}
  </ClassNames>
);

/**
 * The styles for each renderable calendar day.
 * @param {DayPickerProps} props - The props for the given calendar day
 * @returns ReactJSXElement
 */
const StyledPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    ![
      'dayIsBetween',
      'isStart',
      'isEnd',
      'isWeekStart',
      'isWeekEnd',
      'isMonthStart',
      'isToday',
      'isMonthEnd',
      'isOutsideRange',
    ].includes(prop),
})<DayPickerProps>(
  ({
    theme,
    dayIsBetween,
    isStart,
    isEnd,
    isWeekStart,
    isWeekEnd,
    isOutsideRange,
    isMonthStart,
    isMonthEnd,
    isToday,
  }) => {
    //#region conditionally setting border radius
    // ┌─┐
    // └─┘
    const borderRadius = ['0', '0', '0', '0'];
    const isHighlighted = dayIsBetween || isStart || isEnd;
    // ╭─
    // ╰─
    if (isStart || isWeekStart || isMonthStart || isOutsideRange) {
      borderRadius[0] = '50%';
      borderRadius[3] = '50%';
    }
    // ─╮
    // ─╯
    if (isEnd || isWeekEnd || isMonthEnd || isOutsideRange) {
      borderRadius[1] = '50%';
      borderRadius[2] = '50%';
    }
    //#endregion
    return `
      border-radius: ${borderRadius.join(' ')};
      ${isHighlighted ? `background: ${theme?.palette?.primary?.main};` : ''}
      ${isHighlighted ? `color: ${theme?.palette?.primary?.contrastText}` : ''};
      ${/* Disable the border for todays date if it is already highlighted */ ''}
      ${(isStart || isEnd || dayIsBetween) && !isToday ? 'border: none !important;' : ''}

      ${/* Today */ ''}
      ${
        isToday
          ? `
            border: none !important;
            ${
              isHighlighted
                ? `
                  &, &:focus {
                    background: ${theme?.palette?.primary[400]};
                  }
                  &:hover {
                    background: ${theme?.palette?.primary[300]};
                  }
                `
                : `
                  background: ${theme?.palette?.background.verylight};
                  &:hover {
                    background: ${theme?.palette?.primary[100]};
                  }
                `
            }
          `
          : `
            &:hover {
              ${dayIsBetween || isStart || isEnd ? `background: ${theme?.palette?.primary?.dark};` : ''}
            }

            &:focus {
              background: ${dayIsBetween || isStart || isEnd ? theme?.palette?.primary?.main : ''};
            }
          `
      }
    `;
  }
) as React.ComponentType<DayPickerProps>;
//#endregion

//#region utils
function batchAssertEquality(
  date: Moment,
  granularity: moment.unitOfTime.StartOf,
  ...dates: (Moment | null)[]
): boolean[] {
  return dates.map((day: Moment | null) => date.isSame(day, granularity));
}
//#endregion

//#region demo
export function Demo() {
  const [start, setStart] = useState<Moment | null>(moment().subtract(1, 'week'));
  const [end, setEnd] = useState<Moment | null>(moment());
  return <DatePicker start={start} end={end} setStart={setStart} setEnd={setEnd} />;
}
//#endregion

/**
 * A date ranger picker that allows to select any date range given two specific dates.
 * @namespace Shared
 * @param {moment} start - The start date of the range.
 * @param {moment} end - The end date of the range.
 * @param {moment} maxDate - The maximum date that can be selected.
 * @returns ReactJSXElement
 */
export default function DatePicker(props: DatePickerProps) {
  /**
   * For the start and end date values, start will ALWAYS be the min()
   * of the two even if the user selects the end date first.
   */
  const {
    start: parentStart,
    end: parentEnd,
    setStart: setParentStart,
    setEnd: setParentEnd,
    buttonProps,
    variant,
    label,
    error,
    required,
    ...rest
  } = props;
  const today = moment();

  const [start, setStart] = useState<Moment | null>(parentStart);
  const [end, setEnd] = useState<Moment | null>(parentEnd);

  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setStart(parentStart);
    setEnd(parentEnd);
  }, [parentEnd, parentStart]);

  //#region day renderer
  // Leave these as unknown otherwise the type checker will complain
  const renderWeekPickerDay = (_date: unknown, _: unknown, _pickersDayProps: unknown) => {
    /* Type Assertations */
    const date = _date as Moment;
    const pickersDayProps = _pickersDayProps as PickersDayProps<Moment>;

    /* Start/End Dates */
    // idk why, just use weekday() instead of startOf('week') because
    // it doesn't work otherwise
    const weekStart = date.clone().weekday(0);
    const weekEnd = date.clone().weekday(6);
    const monthStart = date.clone().startOf('month');
    const monthEnd = date.clone().endOf('month');

    /* Conditionals */
    const dayIsBetween = start && end ? date > start && date < end : false;
    const [isWeekStart, isWeekEnd, isMonthStart, isMonthEnd, isStart] = batchAssertEquality(
      date,
      'day',
      weekStart,
      weekEnd,
      monthStart,
      monthEnd,
      start
    );
    const isEnd = end ? date.isSame(end, 'day') : isStart ? true : false;
    const isOutsideRange = !dayIsBetween && !isStart && !isEnd;

    /* Handle Clickity Clicks */
    const pickersDayClicked = () => {
      if (end) {
        // restart range selection if a range already exists
        setEnd(null);
        setStart(date);
      } else if (start) {
        if (date < start) {
          // if the date is before the start date, switch it and set the new start date
          const temp = start;
          setStart(date);
          setEnd(temp);
        } else if (!date.isSame(start, 'day')) {
          // just make sure the same day isn't clicked twice
          setEnd(date);
        }
      } else {
        // if no start date is set, set it
        setStart(date);
      }
    };

    return (
      <StyledPickersDay
        {...pickersDayProps}
        key={pickersDayProps.key}
        disableMargin
        isEnd={isEnd}
        isStart={isStart}
        isWeekEnd={isWeekEnd}
        isMonthEnd={isMonthEnd}
        isWeekStart={isWeekStart}
        onClick={pickersDayClicked}
        isMonthStart={isMonthStart}
        dayIsBetween={dayIsBetween}
        isOutsideRange={isOutsideRange}
        isToday={date.isSame(today, 'day')}
      />
    );
  };
  //#endregion

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledDatePicker
        {...rest}
        value={null}
        closeOnSelect={false}
        onChange={() => {
          return;
        }}
        PopperProps={{
          anchorEl: inputRef.current,
        }}
        open={open}
        onClose={() => {
          setOpen(false);
          if (required) {
            if (start) {
              setParentStart(start);
              setParentEnd(end ?? start);
            } else {
              setParentStart(null);
              setParentEnd(null);
            }
          } else {
            setParentStart(start);
            setParentEnd(end);
          }
        }}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => {
          const yearInsert = start?.year() === end?.year() ? '' : '/YYYY ';
          const startFormatted = start ? start.format(`M/D${yearInsert}`) : '';
          const endFormatted = end ? end.format(` M/D${yearInsert}`) : '';
          const showEnd = end && !end.isSame(start, 'day');
          const value = `${startFormatted}${showEnd ? `-${endFormatted}` : ''}`;
          return variant === 'textinput' ? (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                value,
              }}
              ref={inputRef as any}
              value={value}
              label={label}
              focused={false}
              helperText={error}
              error={Boolean(error)}
              disabled={params.disabled}
              onClick={() => setOpen(true)}
              placeholder="Select a date range"
              InputProps={{
                readOnly: true,
                endAdornment: <CalendarMonth />,
                sx: {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  '&, & > input': {
                    cursor: 'pointer !important',
                  },
                },
              }}
            />
          ) : (
            <Button
              ref={inputRef as any}
              disabled={params.disabled}
              onClick={() => setOpen(true)}
              endIcon={<ArrowDropDown />}
              {...buttonProps}
            >
              {value || 'Select a date range'}
            </Button>
          );
        }}
        disableMaskedInput
        views={['year', 'month', 'day']}
      />
    </LocalizationProvider>
  );
}
