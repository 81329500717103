import { FeatureFlagDefinition } from '../types/feature';

// ALL ID's ARE AUTOMATICALLY PREFIXED WITH "feature." DURING POST PROCESSING
const defaultFeatureFlags: FeatureFlagDefinition[] = [
  {
    id: 'sotPublish',
    name: 'SOT Publish',
    enabled: false,
    description: "Allows user's to publish SOT configurations to the CDN",
  },
  {
    id: 'configureConsent',
    name: 'Configure Consent',
    enabled: false,
    description: "Allows user's to configure consent settings for an environment",
  },
];

export default defaultFeatureFlags;
