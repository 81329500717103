import { z } from 'zod';

const NumericString = z.preprocess((val) => Number(val), z.number());

export const ApiSourceOfTruthCustomizationSummarySchema = z.record(
  z.object({
    types: z.array(z.string()),
    totalPagesWithVariable: NumericString.optional(),
    totalPagesWithAutoRulesForVariable: NumericString.optional(),
    totalPagesHaving25OrMoreValuesForVariable: NumericString.optional(),
    totalPagesHavingMoreThan25PageViewsWithVariable: NumericString.optional(),
    totalPagesHavingMoreThanOneValueForVariable: NumericString.optional(),
    totalPagesNeededCustomRulesForVariable: NumericString.optional(),
    groupPathFriendly: z.string().optional(),

    createGlobalRule: z.boolean().optional(),
    isHighlyDynamic: z.boolean().optional(),
    technicalName: z.string().optional(),
    friendlyName: z.string().optional(),
    group: z.string().optional(),
    totalPagesWithTag: NumericString.optional(),
  })
);

// totalPagesHaving25OrMoreValuesForVariable: '0';
// totalPagesHavingMoreThan25PageViewsWithVariable: '1';
// totalPagesHavingMoreThanOneValueForVariable: '0';
// totalPagesNeededCustomRulesForVariable: '0';
// totalPagesWithAutoRulesForVariable: '1';
// totalPagesWithTag: '2';
// totalPagesWithVariable: '1';

export type ApiSourceOfTruthCustomizationSummary = z.infer<typeof ApiSourceOfTruthCustomizationSummarySchema>;
