import {
  ApiSourceOfTruthCustomizationCustomRule,
  ApiSourceOfTruthCustomizationCustomRuleSchema,
  EnvironmentId,
  TagId,
} from 'api/apiTypes';
import { PostfixRegex } from 'api/apiTypes/postfixLogic';
import { NotificationContext } from 'api/notifications/useNotifications';
import routes from 'api/routes';
import { error, isErrored, isLoading, loading } from 'api/utils';
import { useContext, useEffect } from 'react';
import { ZodIssue } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export function useSourceOfTruthCustomizations(
  envGuid: EnvironmentId | null,
  tagId: TagId,
  match: boolean | 'page' | (string & { __regex: true })
) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const customizationKey = envGuid + tagId;

  const customizations =
    match === true
      ? sourceOfTruthContext.globalCustomizationsCache[customizationKey]
      : sourceOfTruthContext.pageCustomizationsCache[customizationKey];

  useEffect(() => {
    if (envGuid && tagId && !customizations) sourceOfTruthContext.getCustomizations(envGuid, tagId, match);
  }, [sourceOfTruthContext, tagId, customizations, match, envGuid]);

  if (isLoading(customizations)) return loading();
  if (isErrored(customizations)) return error(customizations.messages);

  return customizations;
}

export function useSourceOfTruthCustomizationsErrors(
  envGuid: EnvironmentId | null,
  _tagId: TagId,
  match: boolean | 'page' | (string & { __regex: true })
) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const customizationKey = envGuid + _tagId + '-errors';

  const customizations =
    match === true
      ? sourceOfTruthContext.globalCustomizationsCache[customizationKey]
      : sourceOfTruthContext.pageCustomizationsCache[customizationKey];

  if (isLoading(customizations)) return [];
  if (isErrored(customizations)) return [];

  return customizations as any as (ZodIssue & { context?: string })[];
}

export function useCreateSourceOfTruthCustomization(envGuid: EnvironmentId | null, tagId: TagId) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const alertingContext = useContext(NotificationContext);
  const { post } = useAxios();

  //const customizations = sourceOfTruthContext.customizationsCache[tagId];

  //if (isLoading(customizations)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const create = async (customization: ApiSourceOfTruthCustomizationCustomRule) => {
    if (!envGuid) return;
    const data = ApiSourceOfTruthCustomizationCustomRuleSchema.parse({
      ...customization,
      isEnabled: true,
    });
    await post(routes.sourceOfTruth.customizations.create(envGuid, tagId), data, {
      withCredentials: true,
    });
    const customizationKey = envGuid + tagId;
    const updateState =
      data.pageUrlMatcher === true
        ? sourceOfTruthContext.setGlobalCustomizationsCache
        : sourceOfTruthContext.setPageCustomizationsCache;
    updateState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
    const key: `${EnvironmentId}-${TagId}` = `${envGuid}-${tagId}`;
    alertingContext.groupPaths[key] = loading();
    if (data.pageUrlMatcher === true) {
      sourceOfTruthContext.globalCustomizationIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageCustomizationIds.current?.delete(customizationKey);
    }
  };

  return create;
}

export function useUpdateSourceOfTruthCustomization(envGuid: EnvironmentId | null, tagId: TagId) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { patch } = useAxios();

  // const customizations = sourceOfTruthContext.customizationsCache[tagId];

  // if (isLoading(customizations)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const update = async (customization: ApiSourceOfTruthCustomizationCustomRule) => {
    if (!envGuid) return;
    const data = ApiSourceOfTruthCustomizationCustomRuleSchema.parse(customization);
    await patch(routes.sourceOfTruth.customizations.update(envGuid, tagId, data.id), data, {
      withCredentials: true,
    });

    const customizationKey = envGuid + tagId;

    const updateState =
      data.pageUrlMatcher === true
        ? sourceOfTruthContext.setGlobalCustomizationsCache
        : sourceOfTruthContext.setPageCustomizationsCache;
    updateState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
    if (data.pageUrlMatcher === true) {
      sourceOfTruthContext.globalCustomizationIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageCustomizationIds.current?.delete(customizationKey);
    }
  };

  return update;
}

export function useDeleteSourceOfTruthCustomization(envGuid: EnvironmentId | null, tagId: TagId) {
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { delete: deleteReq } = useAxios();

  // const customizations = sourceOfTruthContext.customizationsCache[tagId];

  // if (isLoading(customizations)) return loading();
  //if (isErrored(customizations)) return error(customizations.messages);

  const deleteItem = async (match: string | boolean | PostfixRegex, ruleId: string, _groupPath: string) => {
    if (!envGuid) return;
    await deleteReq(routes.sourceOfTruth.customizations.delete(envGuid, tagId, ruleId), {
      withCredentials: true,
    });

    const customizationKey = envGuid + tagId;

    if (match === true) {
      sourceOfTruthContext.globalCustomizationIds.current?.delete(customizationKey);
    } else {
      sourceOfTruthContext.pageCustomizationIds.current?.delete(customizationKey);
    }

    const updateState =
      match === true
        ? sourceOfTruthContext.setGlobalCustomizationsCache
        : sourceOfTruthContext.setPageCustomizationsCache;
    updateState((prev) => ({
      ...prev,
      [customizationKey]: undefined,
    }));
  };

  return deleteItem;
}
