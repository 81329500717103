import { routes, useAxios } from 'api';
import {
  ApiSourceOfTruthCustomizationCustomRule,
  ApiSourceOfTruthCustomizationCustomRuleSchema,
  ApiSourceOfTruthCustomizationValuesToAddSchema,
  ApiSourceOfTruthCustomizationValuesToIgnoreSchema,
  ApiSourceOfTruthCustomizationVariablesToIgnoreSchema,
  ApiSourceOfTruthRuleSchema,
} from 'api/apiTypes';
import { PostfixFunction, PostfixFunctionSchema, PostfixRegex, PostfixRegexSchema } from 'api/apiTypes/postfixLogic';
import { ApiSourceOfTruthQualifierSchema } from 'api/apiTypes/sourceOfTruth/qualifier';
import { ApiSourceOfTruthCustomizationSummarySchema } from 'api/apiTypes/sourceOfTruth/summary';
import { error, isErrored, isLoading } from 'api/utils';
import React, { useCallback, useRef, useState } from 'react';
import { z, ZodError, ZodIssue } from 'zod';

import type UsersContextType from '../types/context';
import SourceOfTruthContextType from '../types/context';

import { addRecord, SourceOfTruthContext } from '.';

export function SourceOfTruthProvider(props: React.PropsWithChildren) {
  const { get, post } = useAxios();

  const [hasConsumer, setHasConsumer] = useState(false);
  const [sourceOfTruthCache, setSourceOfTruthCache] = useState<UsersContextType['sourceOfTruthCache']>({});
  const [globalSourceOfTruthCache, setGlobalSourceOfTruthCache] = useState<
    UsersContextType['globalSourceOfTruthCache']
  >({});
  const [qualifiersCache, setQualifiersCache] = useState<UsersContextType['qualifiersCache']>({});
  const [globalCustomizationsCache, setGlobalCustomizationsCache] = useState<
    UsersContextType['globalCustomizationsCache']
  >({});
  const [pageCustomizationsCache, setPageCustomizationsCache] = useState<UsersContextType['pageCustomizationsCache']>(
    {}
  );
  // Ignored variables
  const [globalIgnoredVariablesCache, setGlobalIgnoredVariablesCache] = useState<
    UsersContextType['globalIgnoredVariablesCache']
  >({});
  const [pageIgnoredVariablesCache, setPageIgnoredVariablesCache] = useState<
    UsersContextType['pageIgnoredVariablesCache']
  >({});

  // Ignored values
  const [globalIgnoredValuesCache, setGlobalIgnoredValuesCache] = useState<
    UsersContextType['globalIgnoredValuesCache']
  >({});
  const [pageIgnoredValuesCache, setPageIgnoredValuesCache] = useState<UsersContextType['pageIgnoredValuesCache']>({});

  // Added values
  const [globalAddedValuesCache, setGlobalAddedValuesCache] = useState<UsersContextType['globalAddedValuesCache']>({});
  const [pageAddedValuesCache, setPageAddedValuesCache] = useState<UsersContextType['pageAddedValuesCache']>({});

  const [summaryCache, setSummaryCache] = useState<UsersContextType['summaryCache']>({});

  //@audit remove this
  window.ApiObjects['SourceOfTruth'] = {
    sourceOfTruthCache,
    globalSourceOfTruthCache,
  };

  // list of source of truths at the tag level
  const globalTagIds = useRef(new Set<string>());
  const pageTagIds = useRef(new Set<string>());
  // list of qualifiers at the tag level
  const qualifierIds = useRef(new Set<string>());
  // list of customizations at the tag level
  const pageCustomizationIds = useRef(new Set<string>());
  const globalCustomizationIds = useRef(new Set<string>());
  // list of ignored variables at the tag level
  const pageIgnoredVariableIds = useRef(new Set<string>());
  const globalIgnoredVariableIds = useRef(new Set<string>());
  // list of ignored values at the tag level
  const pageIgnoredValueIds = useRef(new Set<string>());
  const globalIgnoredValueIds = useRef(new Set<string>());
  // list of added values at the tag level
  const pageAddedValueIds = useRef(new Set<string>());
  const globalAddedValueIds = useRef(new Set<string>());
  // list of summaries at the report level
  const summaryIds = useRef(new Set<string>());

  const getSourceOfTruth = useCallback<SourceOfTruthContextType['getSourceOfTruth']>(
    (envGuid, tagId, isGlobal = false, date, limit = 15) => {
      if (!date?.fromDate || !date.toDate) return;
      const key = `${tagId}-${date.fromDate.format('YYYY-MM-DD')}-${date.toDate.format('YYYY-MM-DD')}`;
      if (isGlobal) {
        if (globalTagIds.current.has(key)) return;
        globalTagIds.current.add(key);
      } else {
        if (pageTagIds.current.has(key)) return;
        pageTagIds.current.add(key);
      }

      const addCache = isGlobal ? setGlobalSourceOfTruthCache : setSourceOfTruthCache;

      const url = isGlobal
        ? routes.sourceOfTruth.getGlobalSourceOfTruth(envGuid, tagId, date.fromDate, date.toDate)
        : routes.sourceOfTruth.getPageSourceOfTruth(envGuid, tagId, 1, limit, undefined, date.fromDate, date.toDate);

      const func = isGlobal
        ? get(url, { withCredentials: true })
        : post(
            url,
            {
              filters: [
                {
                  dimension: 'PAGE_URL',
                  operator: 'NOT_EQUALS',
                  value: '',
                },
              ],
            },
            { withCredentials: true }
          );

      func
        .then((payload) => {
          try {
            // emulate network latency
            const data = ApiSourceOfTruthRuleSchema.array().parse(payload.data);
            const newSotData = {
              rules: data.map((x) => ({
                ...x,
                groupPathFriendly: `${x.friendlyName} (${x.groupPath})`,
              })),
            };
            addRecord(key, newSotData, addCache);
          } catch (e: any) {
            addRecord(key, error(["Couldn't load sot", e?.message]), addCache);
          }
        })
        .catch((e) => {
          addRecord(key, error(["Couldn't load sot", e?.message]), addCache);
        });
    },
    [get, post]
  );

  const getQualifiers = useCallback<SourceOfTruthContextType['getQualifiers']>(
    (envGuid, tagId) => {
      // check if current report is okay
      // check if current tag is okay
      // check if qualifier is already cached
      if (qualifierIds.current.has(tagId)) return;
      qualifierIds.current.add(tagId);

      // pull new qualifiers

      get(routes.sourceOfTruth.qualifiers.get(envGuid, tagId), {
        withCredentials: true,
      }).then((payload) => {
        try {
          const data = ApiSourceOfTruthQualifierSchema.array().parse(payload.data);
          addRecord(tagId, data, setQualifiersCache);
        } catch (e: any) {
          addRecord(tagId, error(["Couldn't load qualifiers", e?.message]), setQualifiersCache);
        }
      });
    },
    [get]
  );

  const getCustomizations = useCallback<SourceOfTruthContextType['getCustomizations']>(
    (envGuid, tagId, match) => {
      // check if current report is okay

      // check if current tag is okay
      // check if customization is already cached
      const customizationKey = envGuid + tagId;

      if (match === true) {
        if (globalCustomizationIds.current.has(customizationKey)) return;
        globalCustomizationIds.current.add(customizationKey);
      } else {
        if (pageCustomizationIds.current.has(customizationKey)) return;
        pageCustomizationIds.current.add(customizationKey);
      }

      // pull new qualifiers

      get(routes.sourceOfTruth.customizations.get(envGuid, tagId, match), {
        withCredentials: true,
      })
        .then((payload) => {
          const rawDataSchema = ApiSourceOfTruthCustomizationCustomRuleSchema.extend({
            variablePathTranslations: z.object({
              friendlyName: z.string(),
              technicalName: z.string().optional(),
            }),
          }).transform((data) => {
            const { variablePathTranslations, ...rest } = data;
            return {
              ...rest,
              variablePathFriendlyName: `${variablePathTranslations.friendlyName} (${rest.variablePath})`,
            };
          });
          const data = rawDataSchema.array().parse(payload.data);
          addRecord(customizationKey, data, match === true ? setGlobalCustomizationsCache : setPageCustomizationsCache);
          return;

          try {
            const responseSchema = z.object({
              match: z.union([z.string(), z.boolean(), PostfixRegexSchema]),
              rules: z.array(z.any()),
            });
            const ruleSchema = z.object({
              r: z.string(), // rule id
              q: z.string().optional(), // qualifier id
              s: z.record(
                z.object({
                  o: z.union([z.boolean(), PostfixFunctionSchema]), // optional
                  t: ApiSourceOfTruthCustomizationCustomRuleSchema.shape.valueType, // type
                  v: z
                    .union([PostfixFunctionSchema, PostfixRegexSchema, z.array(z.string()), z.string(), z.null()])
                    .default([]), // values
                  friendlyName: z.string().optional(), // friendly name
                  groupPathFriendly: z.string().optional(), // group path friendly
                })
              ),
            });
            const _first = z.array(z.any()).parse(payload.data);
            const dataFirstStep: z.infer<typeof responseSchema>[] = [];
            const errors: (ZodIssue & { context?: string })[] = [];
            _first.forEach((x) => {
              try {
                dataFirstStep.push(responseSchema.parse(x));
              } catch (e: any) {
                if (e instanceof ZodError) errors.push(...e.issues);
              }
            });
            const data: ApiSourceOfTruthCustomizationCustomRule[] = dataFirstStep.flatMap((m) =>
              m.rules
                .filter((y) => Object.keys(y.s).length > 0)
                .flatMap((r) => {
                  try {
                    const ruleSchemaParsed = ruleSchema.parse(r);
                    return Object.entries(ruleSchemaParsed.s).map(([groupPath, x]) => {
                      // not possible to have more than one group
                      const ruleObject = x;

                      let value: string[] = [];

                      if (typeof ruleObject.v === 'string') value = [ruleObject.v];
                      else if (Array.isArray(ruleObject.v)) value = ruleObject.v;
                      else if (PostfixRegexSchema.safeParse(ruleObject.v).success) {
                        const val = ruleObject.v as PostfixRegex;
                        value = [val.$regularExpression.pattern];
                      } else if (PostfixFunctionSchema.safeParse(ruleObject.v).success) {
                        const val = ruleObject.v as PostfixFunction;
                        value = [val.$function];
                      }

                      let match: string | boolean = '';
                      if (m.match === true) match = true;
                      else if (typeof m.match === 'string') match = m.match;
                      else if (PostfixRegexSchema.safeParse(m.match).success) {
                        const val = m.match as PostfixRegex;
                        match = val.$regularExpression.pattern;
                      }
                      const normalizedRule: ApiSourceOfTruthCustomizationCustomRule = {
                        value,
                        pageUrlMatcher: match,
                        variablePath: groupPath,
                        isEnabled: true,
                        id: r.r,
                        qualifierId: r.q,
                        valueType: ruleObject.t,
                        isOptional: ruleObject.o,
                        friendlyName: ruleObject.friendlyName,
                        variablePathFriendlyName: ruleObject.groupPathFriendly,
                      };
                      return normalizedRule;
                    });
                  } catch (e: any) {
                    if (e instanceof ZodError) {
                      e.issues.map((x) =>
                        errors.push({
                          ...x,
                          context: `rule ${r.r}`,
                        })
                      );
                    }
                    return [];
                  }
                })
            );
            addRecord(
              customizationKey,
              data,
              match === true ? setGlobalCustomizationsCache : setPageCustomizationsCache
            );
            addRecord(
              `${customizationKey}-errors`,
              errors as any,
              match === true ? setGlobalCustomizationsCache : setPageCustomizationsCache
            );
          } catch (e: any) {
            addRecord(
              customizationKey,
              error(["Couldn't load customizations", e?.message]),
              match === true ? setGlobalCustomizationsCache : setPageCustomizationsCache
            );
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.dir(e);
          return;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [get]
  );

  const getIgnoredVariables = useCallback<SourceOfTruthContextType['getIgnoredVariables']>(
    (envGuid, tagId, match) => {
      // check if current report is okay

      const customizationKey = envGuid + tagId;

      // check if customization is already cached
      if (match === true) {
        if (globalIgnoredVariableIds.current.has(customizationKey)) return;
        globalIgnoredVariableIds.current.add(customizationKey);
      } else {
        if (pageIgnoredVariableIds.current.has(customizationKey)) return;
        pageIgnoredVariableIds.current.add(customizationKey);
      }

      // pull new qualifiers

      get(routes.sourceOfTruth.ignoredVariables.get(envGuid, tagId, encodeURIComponent(match)), {
        withCredentials: true,
      }).then((payload) => {
        try {
          const _errored: ZodIssue[] = [];
          const valueSchema = z.array(z.union([z.string(), z.null()]));
          const schema = z.array(
            z.object({
              match: z.union([z.string(), z.boolean(), PostfixRegexSchema]),
              value: z.preprocess((d) => {
                const result = z.array(z.any()).parse(d);
                return result.filter((x) => {
                  const errorResult = valueSchema.safeParse([x]);
                  if (!errorResult.success) {
                    _errored.push(...errorResult.error.issues);
                    return false;
                  } else {
                    return true;
                  }
                });
              }, valueSchema),
              localGroupPathMap: z
                .record(
                  z.object({
                    friendlyName: z.string(),
                    technicalName: z.string().optional(),
                  })
                )
                .optional(),
            })
          );
          const data = schema.parse(payload.data);

          const normalizedData = data.flatMap((x) =>
            x.value.map((y) => {
              const groupPath = x.localGroupPathMap?.[y ?? ''];
              return {
                match:
                  x.match === true
                    ? true
                    : typeof x.match === 'object'
                    ? `${x.match.$regularExpression.pattern}`
                    : `${x.match}`,
                groupPath: y,
                groupPathFriendlyName: groupPath ? `${groupPath.friendlyName} (${y})` : y,
              };
            })
          );
          const realData = ApiSourceOfTruthCustomizationVariablesToIgnoreSchema.array().parse(normalizedData);
          addRecord(
            customizationKey,
            realData,
            match === true ? setGlobalIgnoredVariablesCache : setPageIgnoredVariablesCache
          );
        } catch (e: any) {
          addRecord(
            customizationKey,
            error(["Couldn't load ignoredVariables", e?.message]),
            match === true ? setGlobalIgnoredVariablesCache : setPageIgnoredVariablesCache
          );
        }
      });
    },
    [get]
  );

  const getIgnoredValues = useCallback<SourceOfTruthContextType['getIgnoredVariables']>(
    (envGuid, tagId, match) => {
      // check if current report is okay

      // check if current tag is okay
      // check if customization is already cached
      const customizationKey = envGuid + tagId;

      if (match === true) {
        if (globalIgnoredValueIds.current.has(customizationKey)) return;
        globalIgnoredValueIds.current.add(customizationKey);
      } else {
        if (pageIgnoredValueIds.current.has(customizationKey)) return;
        pageIgnoredValueIds.current.add(customizationKey);
      }

      // pull new qualifiers

      get(routes.sourceOfTruth.ignoredValues.get(envGuid, tagId, encodeURIComponent(match)), {
        withCredentials: true,
      }).then((payload) => {
        try {
          const schema = z.array(
            z.object({
              match: z.union([z.string(), z.boolean(), PostfixRegexSchema]),
              value: z.record(z.array(z.string())),
              localGroupPathMap: z
                .record(
                  z.object({
                    friendlyName: z.string(),
                    technicalName: z.string().optional(),
                  })
                )
                .optional(),
            })
          );
          const data = schema.parse(payload.data);
          const newData = data.flatMap((x) =>
            Object.entries(x.value).map(([k, v]) => {
              const groupPath = x.localGroupPathMap?.[k ?? ''];
              return {
                match:
                  x.match === true
                    ? true
                    : typeof x.match === 'object'
                    ? `${x.match.$regularExpression.pattern}`
                    : `${x.match}`,
                groupPath: k,
                values: v,
                groupPathFriendlyName: groupPath ? `${groupPath.friendlyName} (${k})` : k,
              };
            })
          );
          const valueData = ApiSourceOfTruthCustomizationValuesToIgnoreSchema.array().parse(newData);
          addRecord(
            customizationKey,
            valueData,
            match === true ? setGlobalIgnoredValuesCache : setPageIgnoredValuesCache
          );
        } catch (e: any) {
          addRecord(
            customizationKey,
            error(["Couldn't load ignoredValues", e?.message]),
            match === true ? setGlobalIgnoredValuesCache : setPageIgnoredValuesCache
          );
        }
      });
    },
    [get]
  );

  const getAddedValues = useCallback<SourceOfTruthContextType['getAddedValues']>(
    (envGuid, tagId, match) => {
      // check if current report is okay
      // check if current tag is okay
      // check if customization is already cached
      const customizationKey = envGuid + tagId;

      if (match === true) {
        if (globalAddedValueIds.current.has(customizationKey)) return;
        globalAddedValueIds.current.add(customizationKey);
      } else {
        if (pageAddedValueIds.current.has(customizationKey)) return;
        pageAddedValueIds.current.add(customizationKey);
      }

      // pull new qualifiers

      get(routes.sourceOfTruth.addedValues.get(envGuid, tagId, encodeURIComponent(match)), {
        withCredentials: true,
      }).then((payload) => {
        try {
          const schema = z.array(
            z.object({
              match: z.union([z.string(), z.boolean(), PostfixRegexSchema]),
              value: z.record(z.array(z.string())),
              localGroupPathMap: z
                .record(
                  z.object({
                    friendlyName: z.string(),
                    technicalName: z.string().optional(),
                  })
                )
                .optional(),
            })
          );
          const data = schema.parse(payload.data);
          const newData = data.flatMap((x) =>
            Object.entries(x.value).map(([k, v]) => {
              const groupPath = x.localGroupPathMap?.[k ?? ''];
              return {
                match:
                  x.match === true
                    ? true
                    : typeof x.match === 'object'
                    ? `${x.match.$regularExpression.pattern}`
                    : `${x.match}`,
                groupPath: k,
                values: v,
                groupPathFriendlyName: groupPath ? `${groupPath.friendlyName} (${k})` : k,
              };
            })
          );
          const valueData = ApiSourceOfTruthCustomizationValuesToAddSchema.array().parse(newData);
          addRecord(customizationKey, valueData, match === true ? setGlobalAddedValuesCache : setPageAddedValuesCache);
        } catch (e: any) {
          addRecord(
            customizationKey,
            error(["Couldn't load addedValues", e?.message]),
            match === true ? setGlobalAddedValuesCache : setPageAddedValuesCache
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [get]
  );

  const getSummary = useCallback<SourceOfTruthContextType['getSummary']>(
    (envGuid, tagId, fromDate, toDate) => {
      // check if current report is okay

      // check if qualifier is already cached
      if (summaryIds.current.has(tagId)) return;
      summaryIds.current.add(tagId);
      // pull new qualifiers

      const key = `${tagId}-${fromDate.format('YYYY-MM-DD')}-${toDate.format('YYYY-MM-DD')}`;

      get(routes.sourceOfTruth.getSummary(envGuid, tagId, fromDate, toDate), {
        withCredentials: true,
      }).then((payload) => {
        try {
          if (!Array.isArray(payload.data)) throw new Error();
          const map: Record<string, any> = {};
          payload.data.forEach((x) => {
            map[x.group] = {
              ...x,
              groupPathFriendly: `${x.friendlyName} (${x.group})`,
            };
          });
          const data = ApiSourceOfTruthCustomizationSummarySchema.parse(map);
          addRecord(key, data, setSummaryCache);
        } catch (e: any) {
          addRecord(key, error(["Couldn't load ignoredValues", e?.message]), setSummaryCache);
        }
      });
    },
    [get]
  );

  const pruneSummary = useCallback<SourceOfTruthContextType['pruneSummary']>((report, tagId) => {
    // check if current report is okay
    if (isLoading(report) || isErrored(report)) return;
    // check if current tag is okay
    // check if qualifier is already cached
    if (summaryIds.current.has(tagId)) {
      summaryIds.current.delete(tagId);
      setSummaryCache((prev) => {
        const { [tagId]: _, ...rest } = prev;
        return rest;
      });
    }
  }, []);

  //
  const value: SourceOfTruthContextType = {
    // Consumers
    hasConsumer,
    setHasConsumer,

    pageCustomizationIds,
    globalCustomizationIds,

    pageAddedValueIds,
    globalAddedValueIds,

    pageIgnoredValueIds,
    globalIgnoredValueIds,

    pageIgnoredVariableIds,
    globalIgnoredVariableIds,

    // sot
    getSourceOfTruth,
    sourceOfTruthCache,
    setSourceOfTruthCache,
    globalSourceOfTruthCache,
    setGlobalSourceOfTruthCache,

    // Qualifiers
    getQualifiers,
    qualifierIds,
    qualifiersCache,
    setQualifiersCache,

    // Customizations
    getCustomizations,
    pageCustomizationsCache,
    globalCustomizationsCache,
    setPageCustomizationsCache,
    setGlobalCustomizationsCache,

    // Ignored Variables
    getIgnoredVariables,
    pageIgnoredVariablesCache,
    globalIgnoredVariablesCache,
    setPageIgnoredVariablesCache,
    setGlobalIgnoredVariablesCache,

    // Ignored Values
    getIgnoredValues,
    pageIgnoredValuesCache,
    globalIgnoredValuesCache,
    setPageIgnoredValuesCache,
    setGlobalIgnoredValuesCache,

    // Added Values
    getAddedValues,
    pageAddedValuesCache,
    globalAddedValuesCache,
    setPageAddedValuesCache,
    setGlobalAddedValuesCache,

    // Summary
    getSummary,
    pruneSummary,
    summaryCache,
    setSummaryCache,
  };

  return <SourceOfTruthContext.Provider value={value} {...props} />;
}
