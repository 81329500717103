import { EnvironmentId, TagId, TagIdSchema } from 'api/apiTypes';
import { MissingTagRulesRoutes } from 'api/routes/missingTagRules';
import ApiLoading from 'api/types/base/apiLoading';
import useSWR, { SWRResponse } from 'swr';
import { z } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

import {
  ApiSourceOfTruthTagRemoval,
  ApiSourceOfTruthTagRemovalCreate,
  ApiSourceOfTruthTagRemovalSchema,
  ApiSourceOfTruthTagRemovalUpdate,
} from './schema';

export * from './schema';

export function useMissingTagRules(envGuid: EnvironmentId | null): SWRResponse<ApiSourceOfTruthTagRemoval[]> {
  const { get } = useAxios();

  return useSWR(envGuid ? MissingTagRulesRoutes.get(envGuid) : null, async (url) => {
    return z
      .array(z.any())
      .parse(
        (
          await get(url, {
            withCredentials: true,
          })
        ).data
      )
      .filter((x) => ApiSourceOfTruthTagRemovalSchema.safeParse(x).success);
  });
}

export function useCreateMissingTagRules(
  envGuid: EnvironmentId | null
): ApiLoading | ((tagId: TagId, data: ApiSourceOfTruthTagRemovalCreate) => Promise<void>) {
  const { post } = useAxios();

  return async (tagId: TagId, data: ApiSourceOfTruthTagRemovalCreate) => {
    if (!envGuid) return;
    if (!TagIdSchema.safeParse(tagId).success) throw new Error('Invalid tagId');
    await post(MissingTagRulesRoutes.create(envGuid, tagId), data, {
      withCredentials: true,
    });
  };
}

export function useUpdateMissingTagRules(
  envGuid: EnvironmentId | null
): ApiLoading | ((tagId: TagId, ruleId: string, data: ApiSourceOfTruthTagRemovalUpdate) => Promise<void>) {
  const { patch } = useAxios();

  return async (tagId: TagId, ruleId: string, data: ApiSourceOfTruthTagRemovalUpdate) => {
    if (!envGuid) return;
    if (!TagIdSchema.safeParse(tagId).success) throw new Error('Invalid tagId');
    if (!ruleId.length) throw new Error('Invalid ruleId');
    await patch(MissingTagRulesRoutes.update(envGuid, tagId, ruleId), data, {
      withCredentials: true,
    });
  };
}

export function useDeleteMissingTagRules(
  envGuid: EnvironmentId | null
): ApiLoading | ((tagId: TagId, ruleId: string) => Promise<void>) {
  const { delete: axiosDelete } = useAxios();

  return async (tagId: TagId, ruleId: string) => {
    if (!envGuid) return;
    if (!TagIdSchema.safeParse(tagId).success) throw new Error('Invalid tagId');
    if (!ruleId.length) throw new Error('Invalid ruleId');
    await axiosDelete(MissingTagRulesRoutes.update(envGuid, tagId, ruleId), {
      withCredentials: true,
    });
  };
}
